import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { sendViewEvent } from '../services/AppscasaAnalytics';
import { Helmet } from 'react-helmet';

const About = () => {


  useEffect(()=>{
    sendViewEvent(window.location.pathname);
  })


  return (
    <>
     <Helmet>
        <title>Mobile App Development Company</title>
        <meta name="description" content="Appscasa is helping business of all size by providing the best Mobile App Development services" />
      </Helmet>
   <Navbar/>
   <>
   
  <header className="pages valign bg-img parallaxie" data-background="assets/img/bg.jpg" data-overlay-dark={7}>
    <div className="container">
      <div className="row">
        <div className="full-width mt-80">
          <h5>About Us</h5>
          <h6><Link to="/">Home</Link>/<Link to="/about" className="active">About Us</Link></h6>
        </div>
      </div>
    </div>
  </header>


  

  <section className="about section-padding text-center" data-scroll-index={1}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="intro text-center">
                <span>-------------------------</span>
                <h4>About Us.</h4>
                <p>Appscasa is helping business of all size by providing the best mobile app development services. We have nice cool developers who are experts in their mobile app technology.(React Native, Flutter, Android, IOS).
We had also started to provide best online training to small and big organisation or individual person in mobile app Technology.
.</p>
              </div>
            </div>

          
            

          </div>
        </div>
      </section>


 



  


  
 
</>



<Footer/>

    </>
  )
}

export default About