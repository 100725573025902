import React from 'react'

const BlogFooter = () => {
  return (
    <div style={
      {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection:'column'
      }
    }>
    <h7 style={{width:'55%',marginBottom:10}}>
   😄 Thank you for reading this blog. If you want any help on web and app development then don't
    hesitate to get in touch with us at the given below email address.
    <b> info@appscasa.com 😄</b>
    </h7>
  </div>
  )
}

export default BlogFooter
