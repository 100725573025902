import React from 'react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'

const Navbar = () => {

  const [Courses, setCourses] = useState([]);

    
  useEffect(() => {


    const getCourses = 'https://appscasa.com/backend/public/api/course';
    fetch(getCourses).then((res) => res.json())
      .then((res) => setCourses(res.data));

  }, []);


  return (
    <>
    <nav className="navbar change navbar-expand-lg">
  <div className="container">
   
    <a className="logo" href="/">
      <img src="/Appscasa.jpeg" alt="logo"  style={{borderRadius: '10px'}}/>
    </a>
    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span className="icon-bar"><i className="fas fa-bars" /></span>
    </button>
   
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link active" to="/" data-scroll-nav={0}>Home</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/about" data-scroll-nav={1}>About</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/services" data-scroll-nav={2}>Services</Link>
        </li>

       


        <li className="nav-item dropdown">
  <a className="nav-link dropdown-toggle" data-toggle="dropdown" href="/" role="button" aria-haspopup="true" aria-expanded="false">Courses</a>
  <div className="dropdown-menu">
  {
       Courses.map((add) => (
    <Link className="dropdown-item"  to={`/courses`} state={add.id}>{`${add.title}`}</Link>  

    ))
  }
  </div>
</li>


 


        <li className="nav-item">
          <Link className="nav-link" to="/pricing" data-scroll-nav={4}>Pricing</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/blogs" data-scroll-nav={4}>Blogs</Link>
        </li>
        
        <li className="nav-item">
          <Link className="nav-link butn butn-bg" to="/contact"><span>Contact</span></Link>
        </li>
      </ul>
    </div>
  </div>
</nav>




    </>
  )
}

export default Navbar