import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'

const WhatspButton = () => {
  return (
    <>
    <FloatingWhatsApp 
   phoneNumber='+919304816814' 
   accountName='Appscasa'
   avatar='/Appscasa.jpeg'
   chatMessage= {`Hi there !\nWelcome to Appscasa,\nWe are online and ready to help ?`}
   statusMessage ='Contact you within 5 minute'
   messageDelay={5}
   />
   </>
  )
}

export default WhatspButton
