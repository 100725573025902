// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const appscasaFirebaseConfig = {
    apiKey: "AIzaSyCdEc-mY2QIQz9OIqdyxqgTW_DD2BXHxik",
    authDomain: "appscasa-fc0b7.firebaseapp.com",
    projectId: "appscasa-fc0b7",
    storageBucket: "appscasa-fc0b7.appspot.com",
    messagingSenderId: "951168383143",
    appId: "1:951168383143:web:7af8c135f341da2d8ec382",
    measurementId: "G-TTFYS2M6SP"
  };
export {appscasaFirebaseConfig};