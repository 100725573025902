import React from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { BLOGS_API } from "../constant/ApiUtis";
import BlogFooter from "./BlogFooter";
import { sendViewEvent } from "../services/AppscasaAnalytics";
import { Helmet } from "react-helmet";

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    sendViewEvent(window.location.pathname);

    fetch(BLOGS_API)
      .then((res) => res.json())
      .then(async (res) => {
        let blogs = res.data;
        setBlogs(blogs);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>
          Appscasa Blog : Best Mobile App Development and Web App Development
          Blogs.
        </title>
        <meta
          name="description"
          content="Appscasa has Best Mobile and Web Development Blogs and Case Studies."
        />
      </Helmet>
      <Navbar />

      <header
        className="pages valign bg-img parallaxie"
        data-background="assets/img/bg.jpg"
        data-overlay-dark={7}
      >
        <div className="container">
          <div className="row">
            <div className="full-width mt-80">
              <h5>Our Blogs</h5>
              <h6>
                <Link to="/">Home</Link>/
                <a href="/" className="active">
                  All Blogs
                </a>
              </h6>
            </div>
          </div>
        </div>
      </header>

      <section className="blog section-padding" data-scroll-index={7}>
        <div className="container">
          <div className="row">
            <div className="section-head text-center col-sm-12">
              <h6 className="sm-title">Latest News</h6>
              <h4>Our Blog</h4>
            </div>

            {blogs.map((add) => {
              return (
                <div className="col-lg-4 col-md-6">
                  <div className="item mb-sm50">
                    <div className="post-img">
                      <Link to={`/details`} state={add.id} className="img">
                        <img
                          src={
                            "https://appscasa.com/backend/public/images/" +
                            add.image +
                            ""
                          }
                          alt=""
                        />
                      </Link>
                    </div>
                    <div className="cont">
                      <h5>
                        <Link
                          to={`/details`}
                          state={add.id}
                        >{`${add.title}`}</Link>
                      </h5>
                      <Link to={`/details`} state={add.id} className="more">
                        <span>
                          Read More <i className="pe-7s-angle-right-circle" />
                        </span>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Blogs;
