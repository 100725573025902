import React from "react";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";
import BlogFooter from "./BlogFooter";
import { sendViewEvent } from "../services/AppscasaAnalytics";
import { Helmet } from "react-helmet";

const Details = () => {
  const location = useLocation();
  const [details, setDetails] = useState([]);
  const keys = location.state; // Access the shared data

  const apiGet = async () => {
    if (keys) {
      const Details = `https://appscasa.com/backend/public/api/blogdetail?id=${keys}`;
      fetch(Details)
        .then((res) => res.json())
        .then((res) => setDetails(res.data));
    }
  };
  useEffect(() => {
    sendViewEvent(window.location.pathname);
    apiGet();
  }, [keys]);

  return (
    <>
      <Helmet>
        <title>{"Appscasa Blog :" + details.title}</title>
        <meta
          name="description"
          content={details.length !=0 && details.description.substring(1, 150)}
        />
      </Helmet>

      <Navbar />

      <header
        className="pages valign bg-img parallaxie"
        data-background="assets/img/bg.jpg"
        data-overlay-dark={7}
      >
        <div className="container">
          <div className="row">
            <div className="full-width mt-80">
              <h5>Details</h5>
              <h6>
                <Link to="/">Home</Link>/
                <a href="/" className="active">
                  Details
                </a>
              </h6>
            </div>
          </div>
        </div>
      </header>

      <section className="testimonials section-padding">
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="text-center">
                <img
                  src={
                    "https://appscasa.com/backend/public/images/" +
                    details.image +
                    ""
                  }
                  className="img-fluid"
                  alt=""
                  style={{ height: 500, width: "auto" }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-box  faq">
        <div className="container">
          <div className="row">
            {/* <div className="section-head text-center col-sm-12">
        
        <h4>Details</h4>
      </div> */}
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <h6>{details.title}</h6>
              <br />
              <br />
              <div
                dangerouslySetInnerHTML={{ __html: `${details.description}` }}
              />
            </div>
          </div>
        </div>
      </section>
      <BlogFooter />
      <Footer />
    </>
  );
};

export default Details;
