import React, { useState } from "react";
import { appsAlert, isValidEmail, thankYouAlert } from "../helper/HelperUtils";
import { BOOK_NOW_API } from "../constant/ApiUtis";
import { SUCCESS } from "../constant/Constant";

const BookingModal = ({ selectedCategory, setIsOpen }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const _validateRequest = async () => {
    if (name.trim().length === 0) {
      appsAlert("Please enter your name.");
    } else if (email.trim().length === 0) {
      appsAlert("Please enter your email.");
    } else if (!isValidEmail(email)) {
      appsAlert("Please enter your valid email.");
    } else if (subject.trim().length === 0) {
      appsAlert("Please enter your subject.");
    } else if (message.trim().length === 0) {
      appsAlert("Please enter your message.");
    } else {
      let response = await fetch(BOOK_NOW_API, {
        method: "POST",
        body: JSON.stringify({
          name: name,
          email: email,
          reason: subject,
          details: message,
          price: selectedCategory, //Its temp solution
          category: selectedCategory,
        }),
        headers: { "content-type": "application/json" },
      });

      const result = await response.json();
      const { code, msg, data } = result;
      if (code === SUCCESS) {
        setName("");
        setEmail("");
        setMessage("");
        setSubject("");
        setIsOpen(false);
        thankYouAlert();
      }
    }
  };

  return (
    <div
      style={{
        backgroundColor: "#ffb200",
        width: "50%",
        height: "70%",
        zIndex: 100,
        borderRadius: "16",
        left: window.innerWidth / 2,
        top: "140%",
        transform: "translate(-50%, -50%)",
        position: "absolute",
      }}
    >
      <div
        style={{
          left: "50%",
          top: "50%",
          position: "fixed",
          transform: "translate(-50%, -50%)",
        }}
      >
        <h5>Book For Consultation</h5>
        <div style={{ backgroundColor: "gray", height: 1, marginTop: 10 }} />
        <form className="form" style={{ marginTop: 10 }}>
          <input
            id="form_name"
            type="text"
            name="name"
            style={{ marginTop: 5 }}
            value={name}
            onChange={(ev) => setName(ev.target.value)}
            placeholder="Name *"
            required="required"
            data-error="Name is required."
          />
          <input
            id="form_name"
            type="text"
            name="email"
            style={{ marginTop: 5 }}
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            placeholder="Email *"
            required="required"
            data-error="Email is required."
          />
          <input
            id="form_name"
            type="text"
            name="subject"
            style={{ marginTop: 5 }}
            value={subject}
            onChange={(ev) => setSubject(ev.target.value)}
            placeholder="Subject *"
            required="required"
            data-error="Subject is required."
          />
          <textarea
            id="form_message"
            name="message"
            style={{ marginTop: 5 }}
            value={message}
            onChange={(ev) => setMessage(ev.target.value)}
            placeholder="Your Message"
            rows={4}
            required="required"
            data-error="Your message is required."
            defaultValue={""}
          />
          <div className="order">
            <button
              style={{ width: "100%", marginTop: 10 }}
              onClick={() => {
                _validateRequest();
              }}
              type="button"
              className="butn butn-bg"
            >
              <span>Send</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default BookingModal;
