import './App.css';
import Navigation from './navigation/Navigation';
import WhatspButton from './Components/WhatspButton';
import { initializeApp } from "firebase/app";
import { getAnalytics} from "firebase/analytics";
import { appscasaFirebaseConfig } from './constant/Firebases';
import { sendViewEvent } from './services/AppscasaAnalytics';
const app = initializeApp(appscasaFirebaseConfig);
export const analytics = getAnalytics(app);


function App() {
  sendViewEvent(window.location.pathname);
  return (
   <>
   <WhatspButton/>
   <Navigation/>
   </>
  );
}

export default App;
