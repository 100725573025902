import React from 'react'
import Navbar from './Navbar';
import Footer from './Footer';
import { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import { Link} from 'react-router-dom';
import { BLOGS_API, FEATURE_API, JOIN_US_API, SERVICES_API, SLIDER_API } from '../constant/ApiUtis';
import { SUCCESS } from '../constant/Constant';
import { appsAlert, isValidEmail, thankYouAlert } from '../helper/HelperUtils';
import { sendViewEvent } from '../services/AppscasaAnalytics';
import { Helmet } from 'react-helmet';

const Home = () => {

  const [slider, setSlider] = useState([]);
  const [Service, setService] = useState([]);
  const [Features, setFeatures] = useState([]);
  const [email, setEmail] = useState('');
  const [Blogs, setBlogs] = useState([]);
  const navigate = useNavigate();
  


  const getSliderData = async () => {
    try {
      const respones = await fetch(SLIDER_API).then(res => res.json());
      if (respones.code === SUCCESS) {
        setSlider(respones.data[0])
      } 
    } catch (error) {
          console.log(error);
    }
  }

  const sendJoinRequest = async () => {
    if(email.trim().length == 0){
      appsAlert("Please enter your email !");
    }else if(!isValidEmail(email)){
      appsAlert("Please enter your valid email !");
    } else {
    try {
      let response = await fetch(
        JOIN_US_API,
        {
          method: "POST",
          body: JSON.stringify({
            email: email,
          }),
          headers: { "content-type": "application/json" },
        }
      );

      // const result = response.json();

      if(response){
        setEmail('');
        thankYouAlert();
      }

    } catch (error) {
          console.log(error);
    }
  }
  }


  const getBlogsData = async () => {
    try {
      fetch(BLOGS_API).then((res) => res.json())
        .then((res) => setBlogs(res.data));
    } catch (error) {
          console.log(error);
    }
  }

  const getServices = async () => {
    try {
      fetch(SERVICES_API).then((res) => res.json())
        .then((res) => setService(res.data));
    } catch (error) {
          console.log(error);
    }
  }

  const getFeatures = async () => {
    try {
      fetch(FEATURE_API).then((res) => res.json())
        .then((res) => setFeatures(res.data));
    } catch (error) {
          console.log(error);
    }
  }

  useEffect(() => {
     async function getHomeData(){
       getSliderData();
       getBlogsData();
       getServices();
       getFeatures();
     }
     getHomeData();
     sendViewEvent(window.location.pathname);

  }, []);



  return (
    <>
      <Navbar />
      <Helmet>
        <title>Appscasa</title>
        <meta name="description" content="Appscasa is highly skilled Mobile App Development Company In India. We design and develop mobile apps that delight your users and grow your business." />
      </Helmet>
      <header className="header" data-scroll-index={0}>
        <div
          style={{ backgroundImage: `url(${slider.image})` }}
          className="background valign bg-img parallaxie"
          data-overlay-dark={7}
          data-stellar-background-ratio="0.5"
        >
          <img src="assets/img/curve.png" alt="" className="curve" />
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 caption mt-80">
                <h1>{`${slider.title??''}`}</h1>
                <p>{`${slider.description??''}`}</p>
                <form>
                  <input
                    id="subscribe_email"
                    type="email"
                    name="email"
                    value={email}
                    placeholder="Your Email"
                    onChange={(ev) => setEmail(ev.target.value)}
                    />
                  <button onClick={()=>{sendJoinRequest()}} className="butn butn-bg" type ='button'>
                    <span>
                      Join Us <i className="pe-7s-paper-plane" />
                    </span>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </header>

      <section
        className="about section-padding text-center"
        data-scroll-index={1}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="intro text-center">
                <span>Our Services</span>
                <h4>Grow With Us.</h4>
                <p>
                  We are providing mobile app development (Native and Hybrid)
                  using latest Technology and cooperate training on Trending
                  Technology.
                </p>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">Mobile App Consultant</h6>
                <p className="text-left">
                  We always helping to select right process, methodologies and
                  IT strategy for your business.
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">
                  &#x2714; UI/UX Research & Consultant.
                </p>
                <p className="text-left">
                  &#x2714; Technology Stack Selection.
                </p>
                <p className="text-left">
                  &#x2714; Project Architecture Planning..
                </p>
                <p className="text-left">
                  &#x2714; Documentation of project (Flow Diagram).
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">React Native App Development.</h6>
                <p className="text-left">
                  We are best in hybrid app development using React Native or
                  Flutter..
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">
                  &#x2714; Compatible app for iOS and Android..
                </p>
                <p className="text-left">&#x2714; Scalable App Architecture.</p>
                <p className="text-left">&#x2714; Lighter App..</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">IOS App Development.</h6>
                <p className="text-left">
                  We are expert in building IOS app developement, Providing
                  direct communication with developer.
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">&#x2714; Iphone App Development</p>
                <p className="text-left">&#x2714; Ipad App Development.</p>
                <p className="text-left">&#x2714; Maintenance of App.</p>
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">Android App Development.</h6>
                <p className="text-left">
                  Appscasa has delivered 35+ app in android native using java
                  and Kotlin.
                </p>
                <p className="text-left">
                  <b>Services Includes:-</b>
                </p>
                <p className="text-left">&#x2714; Android App Development.</p>
                <p className="text-left">&#x2714; Tab App Development.</p>
                <p className="text-left">&#x2714; Maintenance of App</p>
                <hr />
              </div>
            </div>
            <div className="col-lg-4  ">
              <div className="item">
                <span className="icon pe-7s-diamond" />

                <h6 className="text-left">UI/UX Design</h6>
                <p className="text-left">
                  Design is the key of any app. It’s creating first impression
                  of app. Our designer are providing cool and nice design for
                  your app, we are providing direct communication between our
                  designer and client.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Service End */}

      {/* Features Start */}

      {/* <section className="banner" data-scroll-index={2}>
        <div className="container-fluid">
          {Features.map((add) => (
            <div className="row">
              <div
                className="col-md-6 bg-img bgimg-height-sm"
                style={{ backgroundImage: `url(${add.image})` }}
              />
              <div className="col-md-6 box-padding">
                <div className="content">
                  <span className="sm-title">Awesome Tools</span>
                  <h3 className="mb-60">{`${add.title}`}</h3>
                  <div className="item">
                    <span className={`${add.icon}`} />
                    <div className="cont">
                      <h6>{`${add.s_title}`}</h6>
                      <p>{`${add.description}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section> */}

      {/* <section className="banner section-padding" data-scroll-index={2}>
        <div className="container">
          <div className="row">
            <div className="col-md-6 order2">
              <div className="content">
                <span className="sm-title">Awesome Tools</span>
                <h3>We Provide Almost Every Kind Of Business Solution.</h3>
                <p>
                  Fusce imperdiet justo vitae dui eleifend imperdiet sed ac
                  massa. Eu scelerisque felis imperdiet proin fermentum. Odio
                  pellentesque diam volutpat commodo sed.
                </p>
                <a href="/" className="butn butn-bg">
                  <span>
                    View More <i className="pe-7s-angle-right" />
                  </span>
                </a>
              </div>
            </div>
            <div className="col-md-6 order1 mb-sm50">
              <div className="img circle">
                <img src="assets/img/01.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="process">
        <div
          className="section-padding background bg-img parallaxie"
          data-overlay-dark={7}
          data-background="assets/img/bg1.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="offset-md-3 col-md-6">
                <div className="text-center">
                  <a className="vid" href="https://vimeo.com/127203262">
                    <span className="vid-butn icon play pe-7s-play" />
                  </a>
                  <h4>Explore Our Awesome Introduction Video.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container top">
          <div className="row">
            <div className="col-md-4 padding">
              <div className="item text-center">
                <img src="assets/img/arrow1.png" className="tobotm" alt="" />
                <span className="icon pe-7s-scissors" />
                <h6>
                  <span>01.</span>Analyze
                </h6>
                <p>
                  Fusce imperdiet justo vitae dui eleifend imperdiet sed ac
                  massa scelerisque felis imperdiet proin fermentum.
                </p>
              </div>
            </div>
            <div className="col-md-4 padding">
              <div className="item text-center">
                <img src="assets/img/arrow1.png" alt="" />
                <span className="icon pe-7s-diamond" />
                <h6>
                  <span>02.</span>Strategy
                </h6>
                <p>
                  Fusce imperdiet justo vitae dui eleifend imperdiet sed ac
                  massa scelerisque felis imperdiet proin fermentum.
                </p>
              </div>
            </div>
            <div className="col-md-4 padding">
              <div className="item text-center">
                <span className="icon pe-7s-like2" />
                <h6>
                  <span>03.</span>Result
                </h6>
                <p>
                  Fusce imperdiet justo vitae dui eleifend imperdiet sed ac
                  massa scelerisque felis imperdiet proin fermentum.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <div className="call-action text-center">
        <div
          className="background bg-img parallaxie pt-80 pb-80"
          data-background="assets/img/bg2.jpg"
          data-overlay-dark={7}
        >
          <div className="container">
            <div className="row">
              <div className="offset-lg-3 col-lg-6 offset-md-2 col-md-8">
                <div>
                  <h4>Do you want to connect ? Contact Us</h4>
                  <a onClick={()=>{
                    navigate("/contact")
                    }} href="#0" className="butn butn-bg mt-30">
                    <span>
                      Contact Us
                      <i className="pe-7s-paper-plane" />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog section-padding" data-scroll-index={7}>
        <div className="container">
          <div className="row">
            <div className="section-head text-center col-sm-12">
              <h6 className="sm-title">Latest Blog</h6>
              <h4>Our Blog</h4>
            </div>

            {Blogs.map((add) => (
              <div className="col-lg-4 col-md-6">
                <div className="item mb-sm50">
                  <div className="post-img">
                    <div className="img">
                      <img
                        src={
                          "https://appscasa.com/backend/public/images/" +
                          add.image +
                          ""
                        }
                        alt=""
                      />
                    </div>
                  </div>
                  <div className="cont">
                    <h5>
                      <Link
                        to={`/details`}
                       state={add.id}
                     >{`${add.title}`}</Link>
                    </h5>
                    <a href="#0" className="more">
                      <span>
                        Read More <i className="pe-7s-angle-right-circle" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Home