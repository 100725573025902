import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'

const Help = () => {
  return (
    <>
 <Navbar />

 <header
         className="pages valign bg-img parallaxie"
         data-background="assets/img/bg.jpg"
         data-overlay-dark={7}
       >
        </header>

 <Footer/>

    
    </>
  )
}

export default Help
