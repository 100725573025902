import Navbar from './Navbar'
import Footer from './Footer'
import { useEffect, useState } from 'react'
import { isValidEmail } from '../helper/HelperUtils'
import { CONTACT_US_API } from '../constant/ApiUtis'
import { sendViewEvent } from '../services/AppscasaAnalytics'
import { Helmet } from 'react-helmet'

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [messageError, setMessageError] = useState("");
  const [sucessMessage, setSuccessMessage] = useState("");


  useEffect(()=>{
    sendViewEvent(window.location.pathname)
   },[])
 

   
  const validateCotactUs = async () => {
    setNameError('');
    setEmailError('');
    setMessageError('');
    setSubjectError('');
    setSuccessMessage('');
    if (name.trim().length === 0) {
      setNameError("Please enter your name");
    } else if (email.trim().length === 0) {
      setEmailError("Please enter your email");
    } else if (!isValidEmail(email.trim())) {
      setEmailError("Please enter valid email address");
    }  else if (subject.trim().length === 0) {
      setSubjectError("Please enter your subject");
    } else if (message.trim().length === 0) {
      setMessageError("Please enter your message");
    } else {
      let result = await fetch(
        CONTACT_US_API,
        {
          method: "POST",
          body: JSON.stringify({
            name: name,
            email: email,
            subject: subject,
            message: message,
          }),
          headers: { "content-type": "application/json" },
        }
      );

      if (result) {
        setNameError('');
        setEmailError('');
        setMessageError('');
        setSubjectError('');
        setName('');
        setEmail('');
        setMessage('');
        setSubject('');
        setSuccessMessage("Request successfully send we will contact you shortly.");
        // navigate("/contact");
      }
    }
};
 
  




  return (
    <>
     <Helmet>
        <title>Contact Us - The Best Mobile App Developent Company</title>
        <meta name="description" content="Developing best and secure app has never been easier. You can contact us via phone and live chat for help." />
      </Helmet>
      <Navbar />
      <header
        className="pages valign bg-img parallaxie"
        data-background="assets/img/bg.jpg"
        data-overlay-dark={7}
      >
        <div className="container">
          <div className="row">
            <div className="full-width mt-80">
              <h5>Contact Us</h5>
              <h6>
                <a href="/">Home</a>/
                <a href="/" className="active">
                  Contact Us
                </a>
              </h6>
            </div>
          </div>
        </div>
      </header>

      <section className="page-contact section-padding">
        <div className="container">
          <div className="row">
            <div className="section-head text-center col-sm-12">
              <h6 className="sm-title">Get In touch</h6>
              <h4>Contact Us</h4>
            </div>
            <div className="offset-lg-1 col-lg-10">
              <form className="form" id="contact-form" method="post">
                <div className="messages" />
                <div className="controls">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          id="form_name"
                          type="text"
                          name="name"
                          value={name}
                          onChange={(ev) => setName(ev.target.value)}
                          placeholder="Name *"
                          required="required"
                          data-error="Firstname is required."
                        />
                        <p style={{color:'red',marginTop:5}}>{nameError}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          id="form_email"
                          type="email"
                          name="email"
                          value={email}
                          onChange={(ev) => setEmail(ev.target.value)}
                          placeholder="Email *"
                          required="required"
                          data-error="Valid email is required."
                        />
                        <p style={{color:'red',marginTop:5}}>{emailError}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          id="form_subject"
                          type="text"
                          name="subject"
                          value={subject}
                          onChange={(ev) => setSubject(ev.target.value)}
                          placeholder="Subject"
                        />
                      <p style={{color:'red',marginTop:5}}>{subjectError}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <textarea
                          id="form_message"
                          name="message"
                          value={message}
                          onChange={(ev) => setMessage(ev.target.value)}
                          placeholder="Your Message *"
                          rows={4}
                          required="required"
                          data-error="Your message is required."
                          defaultValue={""}
                        />
                        <p style={{color:'red',marginTop:5}}>{messageError}</p>
                      </div>
                    </div>
                    <div className="col-md-12 text-center">
                    <h5 style={{color:'green',marginBottom:10}}>{sucessMessage}</h5>
                      <button
                        type="button"
                        onClick={() => {
                          validateCotactUs();
                        }}
                        className="butn butn-bg"
                      >
                        <span>Send Message</span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="page-contact-info">
        <div className="container">
          <div className="row">
            <div className="col-md-4 item">
              <div className="text-center">
                <span className="icon pe-7s-paper-plane" />
                <h5>Email</h5>
                <p>info@appscasa.com</p>
              </div>
            </div>
            <div className="col-md-4 item">
              <div className="text-center">
                <span className="icon pe-7s-phone" />
                <h5>Phone Call</h5>
                <p className="bold">+91 93048 16814</p>
              </div>
            </div>
            <div className="col-md-4 item">
              <div className="text-center">
                <span className="icon pe-7s-map-marker" />
                <h5>Location</h5>
                <p>
                  Plot No:- 35, Ward 019, Yamuna Enclave 1, Dheeraj Nagar,
                  Near JK Public School, Faridabad, HR - 121003, India
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Contact