import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import BookingModal from '../modal/BookingModal';
import { sendViewEvent } from '../services/AppscasaAnalytics';
import { Helmet } from 'react-helmet';

const Price = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const ANDROID_CATEGORY ='Android';
  const IOS_CATEGORY ='Ios';
  const REACT_NATIVE_CATEGORY ='ReactNative';

  useEffect(()=>{
   sendViewEvent(window.location.pathname)
  },[])


   return (
    <>
     <Helmet>
        <title>Hybrid And Native App Development Price</title>
        <meta name="description" content="Appscasa has Highley Skilled Developer and We provide Less Rate as compared to market." />
      </Helmet>
       <Navbar />
       {isOpen && <BookingModal selectedCategory={selectedCategory} setIsOpen={setIsOpen}/> }

       <header
         className="pages valign bg-img parallaxie"
         data-background="assets/img/bg.jpg"
         data-overlay-dark={7}
       >
         <div className="container">
           <div className="row">
             <div className="full-width mt-80">
               <h5>Pricing</h5>
               <h6>
                 <Link to="/">Home</Link>/
                 <Link to="/testimonials" className="active">
                   Pricing
                 </Link>
               </h6>
             </div>
           </div>
         </div>
       </header>

       <section className="price section-padding bg-gray" data-scroll-index={3}>
         <div className="container">
           <div className="row">
             <div className="section-head text-center col-sm-12">
               <h6 className="sm-title">The Best Offers</h6>
               <h4>Our Pricing</h4>
             </div>
             <div className="col-lg-4 col-md-6">
               <div className="item mb-md50">
                 <div className="cont">
                   <div className="type">
                     <h6>Android</h6>
                   </div>
                   <div className="value">
                     <h4>
                       <span>$</span>12
                     </h4>
                     <p>Per Hours </p>
                   </div>
                   <div className="feat">
                     <ul>
                       <li>
                         <span className="text-left">&#x2714; Development</span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Dedicated Maintenance
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Code review.
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; 90 days free support
                         </span>
                       </li>
                     </ul>
                   </div>
                 </div>
                 <div onClick={()=>{
                  setSelectedCategory(ANDROID_CATEGORY)
                  setIsOpen(!isOpen)}} className="order butn">
                   <span className="butn butn-light">
                     <span>
                       Book Now <i className="pe-7s-angle-right" />
                     </span>
                   </span>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6">
               <div className="item active mb-md50">
                 <div className="cont">
                   <div className="type">
                     <h6>IOS</h6>
                   </div>
                   <div className="value">
                     <h4>
                       <span>$</span>12
                     </h4>
                     <p>Per Hours</p>
                   </div>
                   <div className="feat">
                     <ul>
                       <li>
                         <span className="text-left">&#x2714; Development</span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Dedicated Maintenance
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Code review.
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; 90 days free support
                         </span>
                       </li>
                     </ul>
                   </div>
                 </div>
                 <div className="order">
                   <Link onClick={()=>{
                     setSelectedCategory(IOS_CATEGORY)
                     setIsOpen(!isOpen)
                   }} className="butn butn-light">
                     <span>
                       Book Now <i className="pe-7s-angle-right" />
                     </span>
                   </Link>
                 </div>
               </div>
             </div>
             <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
               <div className="item">
                 <div className="cont">
                   <div className="type">
                     <h6>React Native</h6>
                   </div>
                   <div className="value">
                     <h4>
                       <span>$</span>15
                     </h4>
                     <p>Per Hours</p>
                   </div>
                   <div className="feat">
                     <ul>
                       <li>
                         <span className="text-left">&#x2714; Development</span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Dedicated Maintenance
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; Code review.
                         </span>
                       </li>
                       <li>
                         <span className="text-left">
                           &#x2714; 90 days free support
                         </span>
                       </li>
                     </ul>
                   </div>
                 </div>
                 <div className="order">
                   <Link onClick={()=> { 
                    setSelectedCategory(REACT_NATIVE_CATEGORY)
                    setIsOpen(!isOpen) } } className="butn butn-light">
                     <span>
                       Book Now <i className="pe-7s-angle-right" />
                     </span>
                   </Link>
                 </div>
               </div>
             </div>
           </div>
         </div>
       </section>
       <Footer />
     </>

   );
}

export default Price