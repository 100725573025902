import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { sendViewEvent } from '../services/AppscasaAnalytics'
import { Helmet } from 'react-helmet'


const Coures = () => {
  const location = useLocation();
  const keys = location.state
  const [Cour, setCourses] = useState([]);


  const apiGet = async () => {
    const getCour = (`https://appscasa.com/backend/public/api/coursedetail?course_id=${keys}`);
    fetch(getCour).then((res) => res.json())
      .then((res) => setCourses(res.data));
  }
  useEffect(() => {
    apiGet()
    sendViewEvent(window.location.pathname)
  }, [keys]);

  return (
    <>
     <Helmet>
        <title>Hybrid And Native App Development Courses</title>
        <meta name="description" content="Appscasa provide Corporate Training On Android, IOS, React Native and In ReactJS Technology" />
      </Helmet>
      <Navbar />

      <header
        className="pages valign bg-img parallaxie"
        data-background="assets/img/bg.jpg"
        data-overlay-dark={7}
      >
        <div className="container">
          <div className="row">
            <div className="full-width mt-80">
              <h5>Courses</h5>
              <h6>
                <Link to="/">Home</Link>/
                <Link to="/courses" className="active">
                  Courses
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </header>

      {Cour.map((add) => (
        <section className="banner section-padding" data-scroll-index={2}>
          <div className="container">
            <div className="row">
              <div className="col-md-12 order2">
                <div className="content">
                  <h3>{`${add.titlee}`}</h3>
                  
                  <div
                    dangerouslySetInnerHTML={{ __html: `${add.description}` }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ))}

      <Footer />
    </>
  );
}

export default Coures