import React, { useEffect } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { sendViewEvent } from '../services/AppscasaAnalytics'
import { Helmet } from 'react-helmet'

const Services = () => {

  useEffect(()=>{
    sendViewEvent(window.location.pathname);
  },[])

  return (
    <>
     <Helmet>
        <title>Mobile App Development And Corporate Training</title>
        <meta name="description" content="Appscasa provide Mobile App Development(Android and IOS) and Corporate Training Services" />
      </Helmet>
      <Navbar />
      <header
        className="pages valign bg-img parallaxie"
        data-background="assets/img/bg.jpg"
        data-overlay-dark={7}
      >
        <div className="container">
          <div className="row">
            <div className="full-width mt-80">
              <h5>Services</h5>
              <h6>
                <Link to="/">Home</Link>/
                <Link to="/services" className="active">
                  Services
                </Link>
              </h6>
            </div>
          </div>
        </div>
      </header>

      <section
        className="about section-padding text-center"
        data-scroll-index={1}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
              <div className="intro text-center">
                <span>BEST Services</span>
                <h4>OUR SERVICES.</h4>
                <p>
                WE ARE MOBILE APP DEVELOPMENT COMPANY WITH 10+ IN-HOUSE DEVELOPER AND BUILDING THE MOBILE APPLICATION FROM LAST 8+ YEARS AND COMPLETING THE PROJECTS
WITH 100% SUCCESS RATE WITH QUALITY DELIVERY.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">Mobile App Consultant</h6>
                <p className="text-left">
                  We always helping to select right process, methodologies and
                  IT strategy for your business.
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">
                  &#x2714; UI/UX Research & Consultant.
                </p>
                <p className="text-left">
                  &#x2714; Technology Stack Selection.
                </p>
                <p className="text-left">
                  &#x2714; Project Architecture Planning..
                </p>
                <p className="text-left">
                  &#x2714; Documentation of project (Flow Diagram).
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">React Native App Development.</h6>
                <p className="text-left">
                  We are best in hybrid app development using React Native or
                  Flutter..
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">
                  &#x2714; Compatible app for iOS and Android..
                </p>
                <p className="text-left">&#x2714; Scalable App Architecture.</p>
                <p className="text-left">&#x2714; Lighter App..</p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">IOS App Development.</h6>
                <p className="text-left">
                  We are expert in building IOS app developement, Providing
                  direct communication with developer.
                </p>
                <hr />
                <h6 className="text-left">Services Includes:-</h6>
                <p className="text-left">&#x2714; Iphone App Development</p>
                <p className="text-left">&#x2714; Ipad App Development.</p>
                <p className="text-left">&#x2714; Maintenance of App.</p>
              </div>
            </div>

            <div className="col-lg-4 ">
              <div className="item">
                <span className="icon pe-7s-diamond" />
                <h6 className="text-left">Android App Development.</h6>
                <p className="text-left">
                  Appscasa has delivered 35+ app in android native using java
                  and Kotlin.
                </p>
                <p className="text-left">
                  <b>Services Includes:-</b>
                </p>
                <p className="text-left">&#x2714; Android App Development.</p>
                <p className="text-left">&#x2714; Tab App Development.</p>
                <p className="text-left">&#x2714; Maintenance of App</p>
                <hr />
              </div>
            </div>
            <div className="col-lg-4  ">
              <div className="item">
                <span className="icon pe-7s-diamond" />

                <h6 className="text-left">UI/UX Design</h6>
                <p className="text-left">
                  Design is the key of any app. It’s creating first impression
                  of app. Our designer are providing cool and nice design for
                  your app, we are providing direct communication between our
                  designer and client.
                </p>
              </div>
            </div>
            <div className="col-lg-4  ">
              <div className="item">
                <span className="icon pe-7s-diamond" />

                <h6 className="text-left">Corporate Training</h6>
                <p className="text-left">
                  We provide training on mobile app technology.
                </p>
                <p className="text-left">
                  <b>Technology Lists:-</b>
                </p>
                <p className="text-left">&#x2714; React Native</p>
                <p className="text-left">&#x2714; Android</p>
                <p className="text-left">&#x2714; IOS</p>
                <p className="text-left">&#x2714; React JS</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

export default Services